import { Suspense, lazy } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

const Home = lazy(()=>import('./Pages/Home'));
const SobreMi = lazy(()=>import('./Pages/SobreMi'));
const RedesSociales = lazy(()=>import('./Pages/RedesSociales'));
const Proyectos = lazy(()=>import('./Pages/Proyectos'));
const Contacto = lazy(()=>import('./Pages/Contacto'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Suspense><Home></Home></Suspense>}/>
          <Route path="/sobre-mi" element={<Suspense><SobreMi></SobreMi></Suspense>}/>
          <Route path="/redes-sociales" element={<Suspense><RedesSociales></RedesSociales></Suspense>}/>
          <Route path="/proyectos" element={<Suspense><Proyectos></Proyectos></Suspense>}/>
          <Route path="/contacto" element={<Suspense><Contacto></Contacto></Suspense>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
